// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import Zen from '../Zen/Zen';
import SideBar from '../SideBar/SideBar';
import useIsBot from '../../hooks/useIsBot';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import useSlotData from '../../hooks/Page/useSlotData';
import usePrint from '../../hooks/Page/usePrint';
import PreviewExclude from '../PreviewExclude/PreviewExclude';
import useDarkModeChecker from '../../hooks/useDarkModeChecker';

type Props = {
  isBot: boolean,
  aside: ?({ inputTemplate: string, contentLists?: [], }[]),
  miscStyles: ?StyleProps,
};

ArticleAside.defaultProps = {
  isBot: false,
  aside: null,
  miscStyles: null,
};

const getSideList = aside => {
  if (Array.isArray(aside)) {
    const { items, } = aside.find(e => e && e.items) || {};
    const listContent = items
      ? items.find(({ content, }) => content && content.inputTemplate === 'htz_list_List')
      : null;
    return (listContent || {}).content || null;
  }
  return null;
};

function ArticleAside({ miscStyles, }: Props): React$Element<"aside"> {
  const getComponent = useGetComponent();
  const { css, theme, } = useFela();
  const { isBot, } = useIsBot();
  const aside = useSlotData('aside');
  const { isPrint, } = usePrint();
  const isDarkMode = useDarkModeChecker();

  if (isPrint) {
    return null;
  }

  const sideList = isBot && aside?.length ? getSideList(aside) : null;

  const SideListComponent = sideList && getComponent(sideList.inputTemplate);

  const backgroundColor = isDarkMode ? 'transparent' : 'white';

  return (
    <aside
      className={css({
        backgroundColor,
        flexShrink: '0',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        extend: [
          ...(isBot ? [] : [ theme.mq({ until: 'l', }, { display: 'none', }), ]),
          theme.mq({ from: 'l', }, { width: 'calc(300px + 8rem)', }),
          ...(miscStyles
            ? parseStyleProps(miscStyles, theme.mq, theme.type)
            : []),
        ],
      })}
    >
      <PreviewExclude>
        {SideListComponent ? (
          <SideListComponent
            {...sideList}
            {...(isBot ? { isPersonal: false, } : {})}
            viewProps={{ forceView: true, }}
          />
        ) : aside ? (
          <Zen animate>
            <div
              className={css({
                height: '100%',
                end: '0',
                paddingTop: '3rem',
                position: 'absolute',
                top: '0',
              })}
            >
              <SideBar />
            </div>
          </Zen>
        ) : null}
      </PreviewExclude>
    </aside>
  );
}
export default ArticleAside;
