import React, { useState, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import gql from 'graphql-tag';
import Caption from '../Caption/Caption';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import useGetMediaComponent from '../../hooks/useGetMediaComponent';
import isOmnyCustomPlayer from '../../utils/isOmnyCustomPlayer';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useArticleType from '../../hooks/Page/useArticleType';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import Image from '../Image/Image';
import Picture from '../Image/Picture';

import ImageGallery from '../ImageGallery/ImageGallery';

const propTypes = {
  /**
   * Override the caption misc styles.
   */
  captionMiscStyles: PropTypes.shape({}),
  /** aspect to force on article image */
  forceAspect: PropTypes.string,
  /**
   * The media object as it passed down from papi.
   */
  elementObj: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  /**
   * A function that gets the aspect and isFullScreen args
   * should return an objects of imgOptions the includes sizes and transforms for the headline image
   */
  imgOptions: PropTypes.func.isRequired,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
};

const defaultProps = {
  miscStyles: null,
  captionMiscStyles: {},
  forceAspect: 'headline',
};

const { isHDC, } = checkSiteFromConfig();

/**
 * HeadlineElement component receive the JSON of an
 * [Image](./#image), [Gallery](./#gallery), [Embed](./#embed) or [Video](./#video) (legacy)
 * that is set as main element (position 0), and displays it <u>always</u> with aspect `headline`
 * (except for Embeds).
 * @param elementObj
 * @returns {XML}
 * @constructor
 */
function HeadlineElement({ captionMiscStyles, elementObj, forceAspect, imgOptions, miscStyles, }) {
  const { css, theme, } = useFela();
  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = useState(false);
  const articleType = useArticleType();
  const getComponent = useGetComponent();
  const mediaKind = isOmnyCustomPlayer(elementObj)
    ? 'articleHeaderAudio'
    : elementObj.kind || elementObj.inputTemplate;

  const ImageGalleryTeaser = getComponent('imageGalleryTeaser');

  const isGallery = mediaKind === 'Gallery';
  const isStandardArticle = articleType === 'standardArticle';
  const showGallery = isHDC && isStandardArticle && isGallery;

  const getMediaComponent = useGetMediaComponent(
    showGallery ? Picture : undefined,
    showGallery ? ImageGalleryTeaser : undefined
  );

  const Element = getMediaComponent(mediaKind);

  // if the Element is an image. credit prefix should set to 'צילום', issue: #1011
  const isPodcastArticle = articleType === 'podcastArticle';

  if (isWebView && isPodcastArticle) {
    return (
      <div className={css({
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      })}
      >
        <Element
          {...{
            ...elementObj,
            ...(isOmnyCustomPlayer(elementObj)
              ? {
                ...elementObj.settings,
                image: elementObj.image,
              }
              : {}),
          }}
          showCaption={false}
          loadAfterFirstClick={false}
          forceAspect={forceAspect}
          isHeadline
          imgOptions={imgOptions}
        />
      </div>
    );
  }

  return (
    <div
      className={css({
        extend: [
          // Trump all other styles with those defined in `miscStyles`
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
          elementObj.kind === 'image' || elementObj.kind === 'video'
            ? []
            : isOmnyCustomPlayer(elementObj)
              ? theme.mq({ until: 'm', }, { paddingStart: '2rem', paddingEnd: '2rem', })
              : theme.mq({ until: 'l', }, { paddingStart: '2rem', paddingEnd: '2rem', }),
        ],
      })}
    >
      <Element
        {...{
          ...elementObj,
          ...(isOmnyCustomPlayer(elementObj)
            ? { ...elementObj.settings, }
            : {}),
        }}
        showCaption={false}
        loadAfterFirstClick={false}
        forceAspect={forceAspect}
        isHeadline
        imgOptions={imgOptions}
        onLoad={() => { setIsLoaded(true); }}
      />
      {elementObj.inputTemplate !== 'com.polobase.OmnyStudioEmbed' && (!isWebView || isLoaded) ? (
        <Caption
          caption={elementObj.caption || elementObj.title}
          credit={elementObj.credit || elementObj.photographer}
          backgroundColor={[ { until: 's', value: [ 'articleHeader', 'headlineCaptionBgMobile', ], }, ]}
          color={[
            { until: 's', value: [ 'articleHeader', 'headlineCaptionTextMobile', ], },
            { from: 's', value: [ 'articleHeader', 'headlineCaptionText', ], },
          ]}
          miscStyles={{
            paddingStart: '2rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            ...captionMiscStyles,
          }}
        />
      ) : null}
    </div>
  );
}

HeadlineElement.propTypes = propTypes;

HeadlineElement.defaultProps = defaultProps;

export default HeadlineElement;
