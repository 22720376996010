import React, { useMemo, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import gql from 'graphql-tag';
// import log from 'loglevel';
import useIsBot from '../../hooks/useIsBot';

import Query from '../ApolloBoundary/Query';
import Media from '../Media/Media';
import getScrollYPosition from '../../hooks/useScrollYPosition';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import useSlotData from '../../hooks/Page/useSlotData';


const IS_OSAKA_DISPLAYED = gql`
  query IsOsakaDisplayed {
    isOsakaDisplayed @client
  }
`;

const propTypes = {
  /**
   * An array of children.
   */
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

/**
 * The content of this component consists from `com.tm.element.group` types of elements
 * (see query at /htz-components/src/components/PageLayout/queries/standard_article.js, line 30).
 * each element has its 'display duration' (without it it won't pass through papi) which determines
 * for how long (in pixels) the element should appear on the page.
 */
function SideBar() {
  const aside = useSlotData('aside');
  const { y, } = getScrollYPosition();
  const { css, theme, } = useFela();
  const { isBot, } = useIsBot();
  const MediaOverride = isBot ? ({ children, }) => children : Media;
  const getComponent = useGetComponent();
  const content = useMemo(() => (aside || []), [ aside, ]);
  const filteredItems = useMemo(() => content.filter(
    item => item.inputTemplate !== 'AdSlot' && item.inputTemplate !== 'HtmlElement' && item.inputTemplate !== 'DfpBannerHTZ'
  ), [ content, ]);
  const dfpArr = useMemo(() => content.filter(item => item.inputTemplate === 'AdSlot' || item.inputTemplate === 'DfpBannerHTZ'), [ content, ]);
  const carambolas = useMemo(() => content.filter(item => (
    item.inputTemplate === 'HtmlElement' && item.contentName === 'Carambola'
  )), [ content, ]);

  return (
    <Query query={IS_OSAKA_DISPLAYED}>
      {({ error, loading, data, }) => {
        if (error) {
          console.error(error);
          return null;
        }
        if (loading) return null;
        const { isOsakaDisplayed, } = data || {};
        return (
          <MediaOverride query={{ from: 'l', }} matchOnServer>
            <>
              {dfpArr.length > 0 && (
              <div
                className={css({
                  marginBottom: '3rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                })}
              >
                {dfpArr.map(dfpItem => {
                  const Element = getComponent(dfpItem.inputTemplate);
                  const { properties, ...elementWithoutProperties } = dfpItem;
                  return (
                    <Element key={dfpItem.contentId} {...elementWithoutProperties} {...properties} />
                  );
                })}
              </div>
              )}
              {carambolas.map(carambola => {
                const Element = getComponent(carambola.inputTemplate);
                return (
                  <Element
                    key={carambola.contentId}
                    miscStyles={{ marginBottom: '3rem', }}
                    {...carambola}
                  />
                );
              })}
              <div
                className={css({
                  position: 'sticky',
                  width: '100%',
                  top: isOsakaDisplayed ? '28rem' : '2rem',
                  zIndex: '1',
                  paddingInlineStart: '4rem',
                  paddingInlineEnd: '4rem',
                  paddingBottom: '4rem',
                  transitionProperty: 'top',
                  extend: [
                    theme.getDelay('transition', -1),
                    theme.getDuration('transition', -1),
                    theme.getTimingFunction('transition', 'linear'),
                    theme.mq(
                      { from: 'l', until: 'xl', },
                      { paddingInlineStart: '3rem', paddingInlineEnd: '3rem', }
                    ),
                  ],
                })}
              >
                {filteredItems.map(element => {
                  const Element = getComponent(element.inputTemplate);
                  const { properties, ...elementWithoutProperties } = element || {};
                  return (
                    <Element
                      scrollY={y}
                      key={element?.contentId}
                      {...elementWithoutProperties}
                      {...properties || {}}
                    />
                  );
                })}
              </div>
            </>
          </MediaOverride>
        );
      }}
    </Query>
  );
}

SideBar.propTypes = propTypes;

export default SideBar;
